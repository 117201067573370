import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import logoImage from "./assets/images/logo.png";
import headerImage from "./assets/images/header.jpg";

const Logo = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;

const HeaderImage = styled.img`
  border-radius: 5px;
  max-width: 100%;
`;

const App = () => (
  <Container maxWidth="md">
    <Box marginY={4}>
      <Logo src={logoImage} alt="Oy Nixell Logistics Ab" />
    </Box>
    <Box marginY={2}>
      <HeaderImage src={headerImage} alt="Oy Nixell Logistics Ab" />
    </Box>
    <Container maxWidth="sm">
      <Box marginY={2}>
        <Typography variant="h3" gutterBottom>
          Oy Nixell Logistics Ab
        </Typography>
        <Typography variant="body1" gutterBottom>
          Oy Nixell Logistics Ab är ett transport bolag som grundats i Närpes,
          Finland år 2012. Före grundandet av aktiebolaget har företaget verkat
          under firmanamnet Fredrik Nixells Transport sedan år 2002. Vårt mål är
          att samordna transporter snabbt och effektivt över hela Finland och
          norra Sverige.
        </Typography>
      </Box>
      <Box marginY={2}>
        <Typography variant="h3" gutterBottom>
          Ta kontakt med oss
        </Typography>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" gutterBottom>
              Beställningar tas emot via:
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" gutterBottom>
              info@nixell.com
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  </Container>
);

export default App;
